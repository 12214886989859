import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dialog-form-cotizar',
    templateUrl: './text.dialog.html'
    , styleUrls: ['../../cotizar/cotizar.component.css', '../../app.component.css', '../../../assets/css/main.css']
})
export class DialogText implements OnInit {

    public texto = "";
    public titulo = "";
    public aceptar = "";
    public columsSpawn = 2;
    public innerWidths = '0';

    constructor(
        private dialogRef: MatDialogRef<DialogText>,
        @Inject(MAT_DIALOG_DATA) public data: {
            texto: string, aceptar: string, titulo: string
        }
    ) {
        this.texto = data.texto;
        this.aceptar = data.aceptar;
        this.titulo = data.titulo;
        this.innerWidths = (window.innerWidth * 0.8) + 'px';
        if (window.innerWidth > 600) {
            this.columsSpawn = 1;
        } else {
            this.columsSpawn = 2;
        }
    }

    ngOnInit(): void {
        var pipe = new DatePipe('en-US');
        if (window.innerWidth > 800) {
            this.columsSpawn = 1;
        } else {
            this.columsSpawn = 2;
        }
        console.log('innerWidth', window.innerWidth);
        console.log('columsSpawn', this.columsSpawn);
    }

    onSubmit() {
        this.dialogRef.close();
    }
}