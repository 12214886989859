import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { API } from 'aws-amplify';
import awsmobile from './aws-exports'

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Amplify.configure({
  awsmobile,
  API: {
    endpoints: [
      {
        name: "springApi",
        endpoint: "https://ois6uo5vyf.execute-api.us-west-2.amazonaws.com/dev/resources"
      }
    ]
  }
})