

export class AppTypes {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idTipo: number,
        public nombre: string
    ) { }
}