import { AppModuloSeleccionado } from "./app.modulos.seleccionado";
import { CasosUso } from "./caso.uso";


export class CasosUsoSeleccioando {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idCaso: number,
        public appModuloSeleccionado: AppModuloSeleccionado,
        public casoUso: CasosUso,
        public nombre: string
    ) { }
}