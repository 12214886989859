export class Zurlrest {
    public static dominio: string = "https://ois6uo5vyf.execute-api.us-west-2.amazonaws.com/dev/resources";
    //public static dominio: string = "http://ec2-54-202-100-36.us-west-2.compute.amazonaws.com:9090";
    //public static dominio: string = "http://ta-consultingw.com:9090";
    //public static dominio: string = "http://localhost:9090/";
    //public static dominio: string = "";

    // public static adicionales: string = Zurlrest.dominio + '/adicionales';
    // public static app_modulo_seleccionado: string = Zurlrest.dominio + '/appmoduloseleccionado';
    // public static app_modulo: string = Zurlrest.dominio + '/appmodulos';
    // public static app_types: string = Zurlrest.dominio + '/apptypes';
    // public static caso_uso_seleccionado: string = Zurlrest.dominio + '/casosusoseleccionado';
    // public static caso_uso: string = Zurlrest.dominio + '/casosuso';
    // public static imagenes: string = Zurlrest.dominio + '/imagenes';
    // public static proyectos: string = Zurlrest.dominio + '/proyectos';
    // public static requerimentos_seleccionado: string = Zurlrest.dominio + '/requerimentoseleccionado';
    // public static requerimentos: string = Zurlrest.dominio + '/requerimentos';
    // public static solicitudes: string = Zurlrest.dominio + '/solicitudes';
    // public static usuarios: string = Zurlrest.dominio + '/usuarios';

    public static adicionales: string =  '/adicionales';
    public static app_modulo_seleccionado: string =  '/appmoduloseleccionado';
    public static app_modulo: string =  '/appmodulos';
    public static app_types: string =  '/apptypes';
    public static caso_uso_seleccionado: string = '/casosusoseleccionado';
    public static caso_uso: string =  '/casosuso';
    public static imagenes: string =  '/imagenes';
    public static proyectos: string =  '/proyectos';
    public static requerimentos_seleccionado: string =  '/requerimentoseleccionado';
    public static requerimentos: string =  '/requerimentos';
    public static solicitudes: string = '/solicitudes';
    public static usuarios: string =  '/usuarios';

}