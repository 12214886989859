import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.off.html',
  styleUrls: ['./app.component.css', './app.component.custom.css', '../assets/css/main.css'],
  animations: [
    trigger('displ', [
      state('closed', style({
        display: 'none',
      })),
      state('open', style({
        display: 'block'
      })),
      transition('closed => open', animate('0ms ease')),
      transition('open => closed', animate('1000ms ease'))
    ]),
    trigger('dispOpac', [
      state('open', style({ opacity: '1' })),
      state('closed', style({ opacity: '0' })),
      transition('open => closed', animate('1000ms ease')),
      transition('closed => open', animate('1000ms ease'))
    ])
  ]
})
export class AppComponent implements OnInit {

  title = 'tac-market';
  aboutActive: boolean;
  whatActive: boolean;
  cotizarActive: boolean;
  aboutActiveString: string;
  whatActiveString: string;
  cotizarActiveString: string;

  constructor(private animateScrollService: NgAnimateScrollService) {
    this.aboutActive = false;
    this.aboutActiveString = "open";
    this.whatActive = false;
    this.whatActiveString = "closed";
    this.cotizarActive = false;
    this.cotizarActiveString = "closed";
  }

  ngOnInit(): void {
    this.aboutActive = true;
  }

  aboutSelect() {
    (this.aboutActiveString == "closed") ? this.aboutActiveString = "open" : this.aboutActiveString = "closed";
    this.whatActiveString = "closed";
    this.cotizarActiveString = "closed";
    this.aboutActive = true;
    this.whatActive = false;
    this.cotizarActive = false;
  }

  whatSelect() {
    (this.whatActiveString == "closed") ? this.whatActiveString = "open" : this.whatActiveString = "closed";
    this.aboutActiveString = "closed";
    this.cotizarActiveString = "closed";
    this.aboutActive = false;
    this.whatActive = true;
    this.cotizarActive = false;
  }

  cotizarSelect() {
    (this.cotizarActiveString == "closed") ? this.cotizarActiveString = "open" : this.cotizarActiveString = "closed";
    this.whatActiveString = "closed";
    this.aboutActiveString = "closed";
    this.aboutActive = false;
    this.whatActive = false;
    this.cotizarActive = true;
  }

  moveHeader(duration?: number) {
    this.animateScrollService.scrollToElement('header', duration)
  }

  moveBotton(duration?: number) {
    this.animateScrollService.scrollToElement('deep', duration)
  }

}
