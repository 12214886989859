import { AppTypes } from "./app.type";


export class Proyectos {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idProyecto: number,
        public appType: AppTypes,
        public nombre: string,
        public nombreUsuario: string,
        public correo: string,
        public telefono: string,
        public descripcion: string,
        public acuerdo: string,
        public fechaEntrega: Date,
        public presupuesto: number,
    ) { 
    }
}