import { AppModulos } from "./app.modulo";
import { Proyectos } from "./proyectos";


export class AppModuloSeleccionado {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idModulo: number,
        public proyecto: Proyectos,
        public modulo: AppModulos,
        public nombre: string
    ) { }
}