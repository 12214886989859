import { Injectable } from '@angular/core';
import { Imagenes } from '../models/imagen';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class ImagenService extends AbSerbice<Imagenes> {

    private url: string = Zurlrest.imagenes;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idImagen;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): Imagenes {
        return EntityParser.parseImagen(data);
    }

}