import { Adicionales } from "../models/adicional";
import { AppModulos } from "../models/app.modulo";
import { AppModuloSeleccionado } from "../models/app.modulos.seleccionado";
import { AppTypes } from "../models/app.type";
import { CasosUso } from "../models/caso.uso";
import { CasosUsoSeleccioando } from "../models/caso.uso.seleccionado";
import { Imagenes } from "../models/imagen";
import { Proyectos } from "../models/proyectos";
import { Requerimentos } from "../models/requerimento";
import { RequerimentoSeleccionado } from "../models/requerimento.seleccionado";
import { Solicitudes } from "../models/solicitud";
import { Usuarios } from "../models/usuario";


export class EntityParser {

    public static parseAdicionales(data: any): Adicionales {
        return new Adicionales(
            data.idAdicional,
            this.parseCasoUsoSeleccionado(data.casoUsoSeleccionado),
            data.descripcion,
            data.valor
        );
    }

    public static parseAppModulos(data: any): AppModulos {
        return new AppModulos(
            data.idModulo,
            this.parseAppType(data.appType),
            data.nombre
        );
    }

    public static parseAppModuloSeleccionado(data: any): AppModuloSeleccionado {
        return new AppModuloSeleccionado(
            data.idModulo,
            this.parseProyecto(data.proyecto),
            this.parseAppModulos(data.modulo),
            data.nombre
        );
    }

    public static parseAppType(data: any): AppTypes {
        return new AppTypes(
            data.idTipo,
            data.nombre
        );
    }

    public static parseCasoUsoSeleccionado(data: any): CasosUsoSeleccioando {
        return new CasosUsoSeleccioando(
            data.idCaso,
            this.parseAppModuloSeleccionado(data.appModuloSeleccionado),
            this.parseCasoUso(data.casoUso),
            data.nombre
        );
    }

    public static parseCasoUso(data: any): CasosUso {
        return new CasosUso(
            data.idCaso,
            this.parseAppModulos(data.appModulo),
            data.nombre,
            data.precioInicial
        );
    }

    public static parseImagen(data: any): Imagenes {
        return new Imagenes(
            data.idImagen,
            data.nombre,
            data.contenido
        );
    }

    public static parseProyecto(data: any): Proyectos {
        return new Proyectos(
            data.idProyecto,
            this.parseAppType(data.appType),
            data.nombre,
            data.nombreUsuario,
            data.correo,
            data.telefono,
            data.descripcion,
            data.acuerdo,
            data.fechaEntrega,
            data.presupuesto
        );
    }

    public static parseRequerimentoSeleccionado(data: any): RequerimentoSeleccionado {
        return new RequerimentoSeleccionado(
            data.idRequerimento,
            this.parseCasoUsoSeleccionado(data.casoUsoSeleccionado),
            this.parseRequerimento(data.requerimento),
            data.nombre,
            data.descripcion,
            data.valor
        );
    }

    public static parseRequerimento(data: any): Requerimentos {
        return new Requerimentos(
            data.idRequerimento,
            this.parseCasoUso(data.casoUso),
            data.nombre,
            data.descripcion,
            data.valor
        );
    }

    public static parseSolicitud(data: any): Solicitudes {
        return new Solicitudes(
            data.idSolicitud,
            data.nombre,
            data.correo,
            data.telefono,
            data.descripcion
        );
    }

    public static parseUsuario(data: any): Usuarios {
        return new Usuarios(
            data.idUsuario,
            data.nombre,
            data.apellido,
            data.correo,
            data.password,
            data.fechaNacimiento
        );
    }
}