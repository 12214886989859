import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import API from '@aws-amplify/api';
import { MessagesTAC } from 'messages.st';
import { Proyectos } from '../models/proyectos';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class ProyectoService extends AbSerbice<Proyectos> {

    private url: string = Zurlrest.proyectos;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idProyecto;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): Proyectos {
        return EntityParser.parseProyecto(data);
    }

    public enviarCorreoTexto(id: number): boolean {
        var f = false;
        API.get('springApi', this.getUrl()+'/correo/texto/'.concat(id.toString()), {}).then(
            data => {
                try {
                    if (data) {
                        f = data;
                    }
                } catch (errorp) {
                    console.log('rest parse error enviarCorreoTexto:', errorp)
                }
            }, error => {
                console.log('rest get error enviarCorreoTexto   ', error);
            }
        ).catch(this.handleErroer);
        // this.rest.getOne(this.getUrl().concat('/correo/texto/').concat(id.toString())).toPromise().then(
        //     data => {
        //         try {
        //             f = data;
        //         } catch (errorp) {
        //             console.log('rest parse error:', errorp)
        //         }
        //     }, error => {
        //         console.log('rest get error', error);
        //     }
        // ).catch(this.handleErroer);
        return f;
    }

    guardarProecto(entity: Proyectos, snackBar: MatSnackBar): boolean {
        var exito = false;
        API.post('springApi', this.getUrl(), { // OPTIONAL
            headers: {'Content-Type':'application/json'},body: entity
        }).then(
            resp => {
                if (resp) {
                    console.log('exitoso');
                    MessagesTAC.openSnackBar('proyecto registrado!','ok',snackBar);
                    exito = true;
                    console.log('respuesta proyecto: ',resp);
                    try {
                        if (resp.idProyecto) {
                            this.enviarCorreoTexto(resp.idProyecto);
                        }
                    } catch (erx) {
                    }
                }
            }, error => {
                console.log('rest post error', error);
            }
        ).catch(this.handleErroer);
        // this.rest.postOne(this.getUrl(), entity)
        //     .subscribe(
        //         resp => {
        //             if (resp) {
        //                 if (resp.idProyecto) {
        //                     console.log('idProyecto***',resp.idProyecto)
        //                     this.enviarCorreoTexto(resp.idProyecto);
        //                 }
        //                 console.log('exitoso');
        //                 exito = true;
        //             }
        //         }
        //     );
        return exito;
    }

    private handleErroer(error: any): Promise<Array<any>> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}