import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import API from '@aws-amplify/api';
import { MessagesTAC } from 'messages.st';
import { Solicitudes } from '../models/solicitud';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class SolicitudService extends AbSerbice<Solicitudes> {

    private url: string = Zurlrest.solicitudes;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idSolicitud;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): Solicitudes {
        return EntityParser.parseSolicitud(data);
    }

    guardarSolicitud(entity: Solicitudes, snackBar: MatSnackBar): boolean {
        var exito = false;
        API.post('springApi', this.getUrl(), { // OPTIONAL
            headers: {'Content-Type':'application/json'},body: entity
        }).then(
            resp => {
                if (resp) {
                    console.log('exitoso');
                    MessagesTAC.openSnackBar('solicitud registrada!','ok',snackBar);
                    exito = true;
                }
            }, error => {
                console.log('rest post error', error);
            }
        ).catch(this.handleErroer);
        return exito;
    }

    private handleErroer(error: any): Promise<Array<any>> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}