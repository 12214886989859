<h2 mat-dialog-title [style.color]="'white'">
    Nueva Cotizacion</h2>
<mat-dialog-content [style.width]="innerWidths">

    <form [formGroup]="proyectoForm">
        <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile [colspan]="2" [rowspan]="1" [style.background]="'green'">
                <div mat-form-field>
                    <h2 [style.color]="'white'">USUARIO</h2>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn" *ngIf="proyectoF.idProyecto.value != 0">
                <mat-form-field appearance="outline">
                    <mat-label>Id</mat-label>
                    <input matInput [style.color]="'white'" id="idProyex" readonly type="number" step="1" formControlName="idProyecto" [ngClass]="{ 'is-invalid': submitted && proyectoF.idProyecto.errors }" />
                    <div *ngIf="submitted && proyectoF.idProyecto.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.idProyecto.errors.required">id is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field class="full-width">
                    <mat-label>Nombre</mat-label>
                    <input matInput id="NameUserx" [style.color]="'white'" type="text" placeholder="Nombre" formControlName="nombreUsuario" [ngClass]="{ 'is-invalid': submitted && proyectoF.nombreUsuario.errors }" />
                    <div *ngIf="submitted && proyectoF.nombreUsuario.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.nombreUsuario.errors.required">nombre is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field class="full-width">
                    <mat-label>Correo</mat-label>
                    <input matInput id="MailUserx" [style.color]="'white'" placeholder="example@exp.com" type="text" formControlName="correo" [ngClass]="{ 'is-invalid': submitted && proyectoF.correo.errors }" (change)="proyectoF.correo.setValue(proyectoF.correo.value.trim())"
                    />
                    <div *ngIf="submitted && proyectoF.correo.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.correo.errors.required">Email is required</mat-error>
                        <mat-error *ngIf="proyectoF.correo.errors.email">Email must be a valid email address</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field class="full-width">
                    <mat-label>Telefono</mat-label>
                    <input matInput id="Telefonox" [style.color]="'white'" type="text" placeholder="2525 2525" formControlName="telefono" [ngClass]="{ 'is-invalid': submitted && proyectoF.telefono.errors }" />
                    <div *ngIf="submitted && proyectoF.telefono.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.telefono.errors.required">telefono is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2" [rowspan]="1" [style.background]="'green'">
                <div mat-form-field>
                    <h2 [style.color]="'white'">NUEVO PROYECTO</h2>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field class="full-width">
                    <mat-label>Nombre</mat-label>
                    <input matInput matInput [style.color]="'white'" id="NameProyex" type="text" placeholder="exampleApp" formControlName="nombre" [ngClass]="{ 'is-invalid': proyectoF.nombre.errors }" />
                    <div *ngIf="submitted && proyectoF.nombre.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.nombre.errors.required">nombre is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field class="full-width">
                    <mat-label>Descripcion</mat-label>
                    <textarea matInput [style.color]="'white'" id="Descripcionx" type="text" placeholder="applicacion web de ventas" formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && proyectoF.descripcion.errors }"></textarea>
                    <div *ngIf="submitted && proyectoF.descripcion.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.descripcion.errors.required">Descripcion is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Estado del proyecto </mat-label>
                    <mat-select required formControlName="acuerdo" [ngClass]="{ 'is-invalid': submitted && proyectoF.acuerdo.errors }">
                        <mat-option mat-option value="diseño">Tengo diseño</mat-option>
                        <mat-option mat-option value="especificaciones">Tengo especificaciones</mat-option>
                        <mat-option value="idea diseño">Tengo idea de diseño</mat-option>
                        <mat-option value="elegir">Elegir especificaciones</mat-option>
                    </mat-select>
                    <!-- <input id="Estadox" type="text" placeholder="tengo el diseño" formControlName="acuerdo" [ngClass]="{ 'is-invalid': submitted && proyectoF.acuerdo.errors }" class="form-control" /> -->
                    <div *ngIf="submitted && proyectoF.acuerdo.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.acuerdo.errors.required">Estado is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Fecha de Entrega</mat-label>
                    <input matInput matInput [style.color]="'white'" id="Fechax" type="date" formControlName="fechaEntrega" [ngClass]="{ 'is-invalid': submitted && proyectoF.fechaEntrega.errors }" />
                    <div *ngIf="submitted && proyectoF.fechaEntrega.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.fechaEntrega.errors.required">fecha is required</mat-error>
                    </div>
                </mat-form-field>
                <!-- <label>{{proyectoF.fechaEntrega.value| date: 'dd MMMM yyyy'}}</label> -->
            </mat-grid-tile>
            <mat-grid-tile [colspan]="columsSpawn">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Tipo de Proyecto </mat-label>
                    <mat-select required formControlName="appType" [ngClass]="{ 'is-invalid': submitted && proyectoF.appType.errors }">
                        <mat-option *ngFor="let tipo of appTypes" [value]="tipo">{{tipo.nombre}}</mat-option>
                    </mat-select>
                    <div *ngIf="submitted && proyectoF.appType.errors" class="invalid-feedback">
                        <mat-error *ngIf="proyectoF.appType.errors.required">tipo de proyecto is required</mat-error>
                    </div>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
    </form>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button (click)='onSubmit()'>Guardar</button>
</mat-dialog-actions>