<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->




<!-- Top Bar Start -->
<div class="top-bar d-none d-md-block" id="home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="top-bar-left">
                    <div class="text">
                        <i class="far fa-clock"></i>
                        <h2>8:00 am - 9:00 pm</h2>
                        <p>Lunes - Viernes</p>
                    </div>
                    <div class="text">
                        <i class="fa fa-phone-alt"></i>
                        <h2>+503 2318 7288</h2>
                        <p>Para consultas</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="top-bar-right">
                    <div class="social">
                        <a href="https://twitter.com/TechnoAdvancedC"><i class="fab fa-twitter"></i></a>
                        <a href="https://www.facebook.com/Techno-Advanced-Consulting-111190327673976"><i class="fab fa-facebook-f"></i></a>
                        <!-- <a href=""><i class="fab fa-linkedin-in"></i></a> -->
                        <a href="https://www.instagram.com/techno_advanced_consulting/"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Top Bar End -->

<!-- Nav Bar Start -->
<div class="navbar navbar-expand-lg bg-dark navbar-dark">
    <div class="container-fluid">
        <a href="#" class="navbar-brand" [style.background-color]="'#1c1c4e'"><span><img src="../../assets/images/white_logo_transparent_background.png" alt=""  /></span></a>
        <button type="button" [style.background-color]="'#1c1c4e'" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
      </button>

        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div class="navbar-nav ml-auto" [style.backgound]="'white'">
                <!-- <a (click)="probarApi()" class="nav-item nav-link active">probar</a> -->
                <a (click)="moveHome(3000)" class="nav-item nav-link active">Home</a>
                <a (click)="moveAbout(3000)" class="nav-item nav-link active">About</a>
                <a (click)="moveBotton(3000)" class="nav-item nav-link active">Contactos</a>
                <a (click)="openDialogNuevo()" class="nav-item nav-link active">Cotizar Nuevo Proyecto</a>
                <!-- <a href="about.html" class="nav-item nav-link">About</a> -->
                <!-- <a href="service.html" class="nav-item nav-link">Service</a> -->
                <!-- <a href="feature.html" class="nav-item nav-link">Feature</a> -->
                <!-- <a href="advisor.html" class="nav-item nav-link">Advisor</a> -->
                <!-- <a href="review.html" class="nav-item nav-link">Review</a> -->
                <!-- <div class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
                    <div class="dropdown-menu">
                        <a (click)="moveBotton(3000)" class="dropdown-item">Contactos</a>
                        <a href="single.html" class="dropdown-item">Single Page</a>
                    </div>
                </div> -->
                <!-- <a href="contact.html" class="nav-item nav-link">Contact</a> -->
            </div>
        </div>
    </div>
</div>
<!-- Nav Bar End -->

<!-- Carousel Start -->
<mat-card [style.padding]="'4px'" [style.height]="'60%'" [style.width]="'100%'">
    <!-- <div [style.position]="'sticky'" class="carousel">
        <ngb-carousel *ngIf="images" [interval]="4000" class="owl-carousel">
            <ng-template ngbSlide class="carousel-item">
                <div class="carousel-img" [@displ]="'open'">
                    <img [src]="images[0]" alt="Random first slide">
                </div>
                <div class=" carousel-text carousel-caption">
                    <h1>El Crecimiento es mutuo</h1>
                    <p>
                        Tu empresa y sitio web podria crecer, al igual que TAC en experiencia
                    </p>
                </div>
            </ng-template>
        </ngb-carousel>
    </div> -->

    <mat-carousel [loop]="true" [interval]="4000" [timings]="'2000ms ease-in'" [maintainAspectRatio]="false" [slideHeight]="'100%'">
        <mat-carousel-slide [overlayColor]="'#1302a2ab'" [image]="images[0]">
            <!-- <div class="carousel-img" [@displ]="'open'">
                <img [src]="images[0]" alt="Random first slide">
            </div> -->
            <div class=" carousel-text carousel-caption" [style.background]="'#00000082'">
                <h1 [style.color]="'#0796fe'">El Crecimiento es mutuo</h1>
                <p>
                    Tu empresa y sitio web podria crecer, al igual que TAC en experiencia
                </p>
                <!-- <div class="carousel-btn">
            <a class="btn" href=""><i class="fa fa-link"></i>Get Started</a>
            <a class="btn btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal"><i class="fa fa-play"></i>Watch Video</a>
        </div> -->
            </div>
        </mat-carousel-slide>
        <mat-carousel-slide [overlayColor]="'#1302a2ab'" [image]="images[1]">
            <!-- <div class=" carousel-img">
                <img [src]="images[1]" alt="Random second slide">
            </div> -->
            <div class=" carousel-text carousel-caption" [style.background]="'#00000082'">
                <h1 [style.color]="'#0796fe'">Mision</h1>
                <p>
                    Proveer a nuestros clientes el talento humano capacitado; productos y servicios basados en estándares de calidad mundial para asegurar la continuidad de los servicios de misión crítica de nuestos clientes.
                </p>
                <!-- <div class="carousel-btn">
            <a class="btn" href=""><i class="fa fa-link"></i>Get Started</a>
            <a class="btn btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal"><i class="fa fa-play"></i>Watch Video</a>
        </div> -->
            </div>
        </mat-carousel-slide>
        <mat-carousel-slide [overlayColor]="'#1302a2ab'" [image]="images[2]">
            <!-- <div class="carousel-img">
                <img [src]="images[2]" alt="Random third slide">
            </div> -->
            <div class="carousel-text carousel-caption " [style.background]="'#00000082'">
                <h1 [style.color]="'#0796fe'">Vision</h1>
                <p>
                    Ser la empresa líder en el desarrollo de productos y prestación de servicios de tecnologpía en la región.
                </p>
                <!-- <div class="carousel-btn">
            <a class="btn" href=""><i class="fa fa-link"></i>Get Started</a>
            <a class="btn btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal"><i class="fa fa-play"></i>Watch Video</a>
        </div> -->
            </div>
        </mat-carousel-slide>
    </mat-carousel>
</mat-card>
<!-- Carousel End -->


<!-- Video Modal Start-->
<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                <!-- 16:9 aspect ratio -->
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Video Modal End -->

<!-- Fact Start -->
<div class="fact">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <div class="fact-item">
                    <img src="../../assets/lib/img/icon-4.png" alt="Icon">
                    <h2>Equipo Calificado</h2>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="fact-item">
                    <img src="../../assets/lib/img/icon-1.png" alt="Icon">
                    <h2>Atencion Personalizada</h2>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="fact-item">
                    <img src="../../assets/lib/img/icon-8.png" alt="Icon">
                    <h2>Proyectos Ampliables</h2>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="fact-item">
                    <img src="../../assets/lib/img/icon-6.png" alt="Icon">
                    <h2>100% Satisfaction</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fact Start -->

<!-- About Start -->
<div class="about" id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="about-img">
                    <div class="about-img-1">
                        <img src="../../assets/lib/img/about-2.jpg" alt="Image">
                    </div>
                    <div class="about-img-2">
                        <img src="../../assets/lib/img/about-1.jpg" alt="Image">
                    </div>
                </div>
            </div>
            <div class="col-md-6" [style.background-color]="'white'">
                <div class="section-header">
                    <p>Conoce Acerca de TAC</p>
                    <h2 [style.font]="'500 32px/40px Roboto, Helvetica Neue, sans-serif'">TECHNO ADVANCED CONSULTING</h2>
                </div>
                <div class="about-text">
                    <p>
                        Somos una empresa de tecnología dedicada a ofrecer nuestro talento Humano productos y servicios que estén a la vanguardia de las últimas tendencias que el mercado demanda para convertir a realidad todas sus ideas de negocio.
                    </p>
                    <!-- <a class="btn" href="">Mas</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About End -->

<!-- Service Start -->
<div class="service">
    <div class="container">
        <div class="section-header" [style.background-color]="'white'">
            <p>Consulting Services</p>
            <h2>Our Best Consulting Services</h2>
        </div>
        <div class="row">

            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-2.png" alt="Icon">
                    <h3>Gestion de Proyetos</h3>
                    <!-- <p>
                        Brindamos ayuda personalizada a nuestros clientes para crear su propuesta
                    </p> -->
                    <a (click)="openDialogText(1)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-3.png" alt="Icon">
                    <h3>Investigacion de Mercado</h3>
                    <p>
                        Adaptamos y brindamos asistencia para las nuevas necesidades
                    </p>
                    
                    <a (click)="openDialogText(2)" class="nav-item nav-link active">mas..</a>
                </div>
            </div> -->

            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-5.png" alt="Icon">
                    <h3>Empresas en Linea</h3>
                    <!-- <p>
                        Conectamos tu empresa a la nueva era digital
                    </p> -->
                    <a (click)="openDialogText(3)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-6.png" alt="Icon">
                    <h3>Manejo de Capital</h3>
                    <!-- <p>
                        Contamos con las herramientas necesarias para tus proyectos
                    </p> -->
                    <a (click)="openDialogText(4)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-7.png" alt="Icon">
                    <h3>Negocio Seguro</h3>
                    <!-- <p>
                        Preferimos tener una comunicacion personalizada para planificar efectivamente
                    </p> -->
                    <a (click)="openDialogText(5)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-1.png" alt="Icon">
                    <h3>Estrategias de Mercado</h3>
                    <!-- <p>
                        Nos adaptamos a las nuevas tecnologias para brindar un servicio seguro y confiable
                    </p> -->
                    <a (click)="openDialogText(6)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-4.png" alt="Icon">
                    <h3>Recurso Humano</h3>
                    <!-- <p>
                        Contamos con servicio outsourcing, para brindar una atencion completamente personalizada
                    </p> -->
                    <a (click)="openDialogText(7)" class="nav-item nav-link active">mas..</a>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6">
                <div class="service-item" [style.background-color]="'white'">
                    <img src="../../assets/lib/img/icon-8.png" alt="Icon">
                    <h3>Online Marketing</h3>
                    <p>
                        Contamos con herramientas para evaluar el flujo de tu sitio web
                    </p>
                    <a (click)="openDialogText(8)" class="nav-item nav-link active">mas..</a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- Service End -->

<!-- Feature Start -->
<div class="feature">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-md-6" [style.max-width]="'100%'" [style.overflow]="'scroll'">
                <div class="feature-img">
                    <img src="../../assets/lib/img/business-man.png" alt="Image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="section-header">
                    <p>Our Feature</p>
                    <h2>¿Porqué elegirnos?</h2>
                </div>
                <p>
                    Nos adaptamos a las necesidades utilizando diferentes tecnologias
                </p>
                <div class="row counters">
                    <div class="col-6">
                        <i class="fa fa-user"></i>
                        <div class="counters-text">
                            <h2 data-toggle="counter-up">25%</h2>
                            <p>Our Staffs</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <i class="fa fa-users"></i>
                        <div class="counters-text">
                            <h2 data-toggle="counter-up">200</h2>
                            <p>Our Clients</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <i class="fa fa-check"></i>
                        <div class="counters-text">
                            <h2 data-toggle="counter-up">300</h2>
                            <p>Completed Projects</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <i class="fa fa-running"></i>
                        <div class="counters-text">
                            <h2 data-toggle="counter-up">50%</h2>
                            <p>Proyectos rapidos</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Feature End -->

<!-- Contact Start -->
<div class="contact" id="contact">
    <div class="container">
        <div class="section-header" [style.background-color]="'white'">
            <p>Get In Touch</p>
            <h2>Ponte en contacto</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-md-5">
                <!-- <div class="contact-info" [style.background-color]="'white'">
                    <div class="contact-icon">
                        <i class="fa fa-map-marker-alt"></i>
                    </div>
                    <div class="contact-text">
                        <h3>Our Head Office</h3>
                        <p>123 Street, New York, USA</p>
                    </div>
                </div> -->
                <div class="contact-info" [style.background-color]="'white'">
                    <div class="contact-icon">
                        <i class="fa fa-phone-alt"></i>
                    </div>
                    <div class="contact-text">
                        <h3>llamanos para ayudarte</h3>
                        <p>+503 2318 7288</p>
                    </div>
                </div>
                <div class="contact-info" [style.background-color]="'white'">
                    <div class="contact-icon">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="contact-text hiden-scroll" [style.max-width]="'100%'" [style.overflow]="'scroll'">
                        <h3>Email para Informacion</h3>
                        <p>info.consultas@ta-consultingcorp.com</p>
                    </div>
                </div>
            </div>
            <div class="col-md-7" [style.background-color]="'white'">
                <div class="contact-form">
                    <div id="success"></div>
                    <form id="cForm" [formGroup]="solicitudForm" (ngSubmit)="guardarSolicitud()">
                        <div class="col-12 col-12-mobilep">
                            <label for="" class="full-width">Nombre</label>
                            <mat-form-field class="full-width">
                                <mat-label>Nombre </mat-label>
                                <input matInput required id="nombre" type="text" formControlName="nombre" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.nombre.errors }" />
                                <div *ngIf="solicitudSubmitted && solicitudF.nombre.errors" class="invalid-feedback">
                                    <mat-error *ngIf="solicitudF.nombre.errors.required">nombre is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-12-mobilep ">
                            <label for="" class="full-width">Correo</label>
                            <mat-form-field class="full-width">
                                <mat-label>Correo </mat-label>
                                <input matInput id="MailUser" type="text" formControlName="correo" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.correo.errors }" (change)="solicitudF.correo.setValue(solicitudF.correo.value.trim())" />
                                <div *ngIf="solicitudSubmitted && solicitudF.correo.errors" class="invalid-feedback">
                                    <mat-error *ngIf="solicitudF.correo.errors.required">Email is required</mat-error>
                                    <mat-error *ngIf="solicitudF.correo.errors.email">Email must be a valid email address</mat-error>
                                </div>
                            </mat-form-field>


                        </div>
                        <div class="col-12 col-12-mobilep ">
                            <label for="" class="full-width">Telefono</label>
                            <mat-form-field class="full-width">
                                <mat-label>Telefono </mat-label>
                                <input matInput id="telefono" type="text" formControlName="telefono" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.telefono.errors }" />
                                <div *ngIf="solicitudSubmitted && solicitudF.telefono.errors" class="invalid-feedback">
                                    <mat-error *ngIf="solicitudF.telefono.errors.required">telefono is required</mat-error>
                                </div>
                            </mat-form-field>

                        </div>
                        <div class="col-12 col-12-mobilep ">
                            <label for="" class="full-width">Descripcion</label>
                            <mat-form-field class="full-width">
                                <mat-label>Descripcion </mat-label>
                                <textarea matInput id="descripcion" type="text" formControlName="descripcion" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.descripcion.errors }">
                                </textarea>
                                <div *ngIf="solicitudSubmitted && solicitudF.descripcion.errors" class="invalid-feedback">
                                    <mat-error *ngIf="solicitudF.descripcion.errors.required">descripcion is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-12-mobilep ">
                            <button class="card btn btn-outline-primary">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->

<!-- Footer Start -->
<div class="footer" id="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-md-6">
                        <div class="footer-contact">
                            <h2>Contactos</h2>
                            <!-- <p><i class="fa fa-map-marker-alt"></i>123 Street, New York, USA</p> -->
                            <p><i class="fa fa-phone-alt"></i>+503 2318 7288</p>
                            <p [style.max-width]="'100%'" [style.overflow]="'scroll'" class="hiden-scroll"><i class="fa fa-envelope"></i>info.consultas@ta-consultingcorp.com</p>
                            <div class="footer-social">
                                <a href="https://twitter.com/TechnoAdvancedC"><i class="fab fa-twitter" [style.color]="'lime'"></i></a>
                                <a href="https://www.facebook.com/Techno-Advanced-Consulting-111190327673976"><i class="fab fa-facebook-f" [style.color]="'lime'"></i></a>
                                <!-- <a href=""><i class="fab fa-youtube" [style.color]="'lime'"></i></a> -->
                                <a href="https://www.instagram.com/techno_advanced_consulting/"><i class="fab fa-instagram" [style.color]="'lime'"></i></a>
                                <!-- <a href=""><i class="fab fa-linkedin-in" [style.color]="'lime'"></i></a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="footer-link">
                            <h2>Quick Links</h2>
                            <a href="">Terms of use</a>
                            <a href="">Privacy policy</a>
                            <a href="">Cookies</a>
                            <a href="">Help</a>
                            <a href="">FQAs</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-5">
                <div class="footer-newsletter">
                    <h2>Newsletter</h2>
                    <p>
                        Lorem ipsum dolor sit amet elit. Quisque eu lectus a leo dictum nec non quam. Tortor eu placerat rhoncus, lorem quam iaculis felis, sed lacus neque id eros.
                    </p>
                    <div class="form">
                        <input class="form-control" placeholder="Email goes here">
                        <button class="btn">Submit</button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="container copyright">
        <div class="row">
            <div class="col-md-6">
                <p>&copy; <a href="#">Techno Advanced Consulting</a>, All Right Reserved.</p>
            </div>
            <div class="col-md-6">
                <p>Designed By <a href="https://htmlcodex.com">HTML Codex</a></p>
            </div>
        </div>
    </div>
</div>
<!-- Footer End -->

<a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>