import { Injectable } from '@angular/core';
import { AppModulos } from '../models/app.modulo';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class AppModuloService extends AbSerbice<AppModulos> {

    private url: string = Zurlrest.app_modulo;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idModulo;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): AppModulos {
        return EntityParser.parseAppModulos(data);
    }

}