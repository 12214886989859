/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "desarrollo.danfmpz0961hr.amplifyapp.com-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://desarrollo.danfmpz0961hr.amplifyapp.com-dev.s3-website-us-west-2.amazonaws.com"
};


export default awsmobile;
