import { Injectable } from '@angular/core';
import { AppTypes } from '../models/app.type';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class AppTypesService extends AbSerbice<AppTypes> {

    private url: string = Zurlrest.app_types;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idTipo;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): AppTypes {
        return EntityParser.parseAppType(data);
    }

}