import { CasosUsoSeleccioando } from "./caso.uso.seleccionado";


export class Adicionales {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idAdicional: number,
        public casoUsoSeleccionado: CasosUsoSeleccioando,
        public descripcion: string,
        public valor: number
    ) { }
}