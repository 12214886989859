<!-- Main -->
<div id="main">
    <!-- Main -->
    <div id="major  large">
        <div class="box ">
            <section>
                <header>
                    <h2>Quiénes somos:</h2>
                </header>
                <h6>Somos una empresa de tecnología dedicada a ofrecer talento humano capacitado; productos y servicios que estén a la vanguardia de las últimas tendencias que el mercado demanda para convertir en realidad todas sus ideas de negocio</h6>
            </section>
            <section>
                <header>
                    <h2>Misión:</h2>
                </header>
                <h6>Proveer a nuestros clientes de talento humano capacitado; productos y servicios basados en estándares de calidad mundial para asegurar la continuidad de los servicios de misión crítica de nuestros clientes</h6>
            </section>
            <section>
                <header>
                    <h2>Visión:</h2>
                </header>
                <h6>Ser la empresa líder en el desarrollo de productos y prestación servicios de tecnología en la región.</h6>
            </section>
            <section>

            </section>
        </div>

    </div>
</div>