<h2 mat-dialog-title [style.color]="'white'">
    {{titulo}}</h2>
<mat-dialog-content [style.width]="innerWidths">


    <mat-grid-list cols="2" rowHeight="100px">
        <!-- <mat-grid-tile [colspan]="2" [rowspan]="1" [style.background]="'green'">
            <div>
                <h2 [style.color]="'white'">{{titulo}}</h2>
            </div>
        </mat-grid-tile> -->
        <mat-grid-tile [colspan]="2" [style.display]="'contents'">
            <mat-label center class="full-width" [style.display]="'contents'" [style.color]="'white'">{{texto}}</mat-label>
        </mat-grid-tile>
    </mat-grid-list>

</mat-dialog-content>
<mat-dialog-actions mat-card-content>
    <button mat-raised-button mat-dialog-close>salir</button>
    <button mat-raised-button (click)='onSubmit()'>{{aceptar}}</button>
</mat-dialog-actions>