import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProyectoService } from '../services/proyecto.service';
import { AppTypesService } from '../services/app.types.service';
import { SolicitudService } from '../services/solicitud.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Solicitudes } from '../models/solicitud';
import { AppTypes } from '../models/app.type';
import { DialogCotizar } from '../cotizar/cotizar.component';
import { Proyectos } from '../models/proyectos';
import API from '@aws-amplify/api';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { DialogText } from './dialogs/text.dialog';

@Component({
  selector: 'app-root-tc',
  templateUrl: './app.component.tc.html',
  styleUrls: ['./imports.css'],
  animations: [
    trigger('displ', [
      state('closed', style({
        display: 'none',
      })),
      state('open', style({
        display: 'block'
      })),
      transition('closed => open', animate('0ms ease')),
      transition('open => closed', animate('1000ms ease'))
    ]),
    trigger('dispOpac', [
      state('open', style({ opacity: '1' })),
      state('closed', style({ opacity: '0' })),
      transition('open => closed', animate('1000ms ease')),
      transition('closed => open', animate('1000ms ease'))
    ])
  ]
  , providers: [NgbCarouselConfig]
})
export class AppComponentTc implements OnInit {
  images = ['carousel-1.jpg', 'carousel-2.jpg', 'carousel-3.jpg', 'carousel-2.jpg'].map((n) => `../../assets/lib/img/${n}`);

  public solicitudForm: FormGroup;
  public solicitudSubmitted: boolean;
  public appTypes: any = [];

  constructor(config: NgbCarouselConfig,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _proyectoService: ProyectoService,
    private _appTypesService: AppTypesService,
    private _solicitudService: SolicitudService,
    private animateScrollService: NgAnimateScrollService,
    private _snackBar: MatSnackBar) {
    this.solicitudForm = this.formBuilder.group({});
    this.solicitudSubmitted = false;
    this.cargarAppTypes();
  }

  probarApi() {
    try {
      API.get('springApi', '/usuarios', {}).then(result => {
        console.log('result', result);
      }).catch(err => {
        console.log('error Spring api: ', err);
      });
    } catch (errorx) {
      console.log('error probar api: ', errorx);
    }

  }

  ngOnInit(): void {
    this.solicitudForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telefono: ['', [Validators.required]],
      descripcion: ['', [Validators.required, Validators.minLength(6)]]
      // ,fecha: [new Date, [Validators.required]]
    });
  }

  cargarAppTypes() {
    var pr = this._appTypesService.getList();
    console.log('apptipe cargadp: ', pr)
    if (pr) {
      this.appTypes = pr;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  guardarSolicitud() {
    this.solicitudSubmitted = true;
    //this.solicitudF.correo.setValue(this.solicitudF.correo.value.trim());
    //this.evaluarConexion();
    if (this.solicitudForm.valid) {
      var solicitudq: Solicitudes = new Solicitudes(
        0,
        this.solicitudF.nombre.value,
        this.solicitudF.correo.value,
        this.solicitudF.telefono.value,
        this.solicitudF.descripcion.value
      );
      this._solicitudService.guardarSolicitud(solicitudq,this._snackBar);
    } else {
      this.openSnackBar('completa el formulario!', 'ok');
    }
  }

  openDialogNuevo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      idProyecto: 0,
      appType: new AppTypes(1, ''),
      nombre: '',
      nombreUsuario: '',
      correo: '',
      telefono: '',
      descripcion: '',
      acuerdo: '',
      fechaEntrega: new Date(),
      presupuesto: 0.00,
      appTypesList: this.appTypes
    };
    const dialogRef = this.dialog.open(DialogCotizar, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log("Dialog output:", result);
          this.crearProyecto(result);
        } else {
          console.log('Dialog vacio');
        }
      });
  }

  openDialogText(id: number) {

    var texd = "";
    var titulod = "";
    var aceptard = "ok!";

    switch (id) {
      case 1: titulod = "Gestion de Proyetos";
        texd = "Brindamos ayuda personalizada a nuestros clientes para crear su propuesta";
        break;
      case 2: titulod = "Investigacion de Mercado";
        texd = "Adaptamos y brindamos asistencia para las nuevas necesidades";
        break;
      case 3: titulod = "Empresas en Linea";
        texd = "Conectamos tu empresa a la nueva era digital";
        break;
      case 4: titulod = "Manejo de Capital";
        texd = "Contamos con las herramientas necesarias para tus proyectos";
        break;
      case 5: titulod = "Negocio Seguro";
        texd = "Preferimos tener una comunicacion personalizada para planificar efectivamente";
        break;
      case 6: titulod = "Estrategias de Mercado";
        texd = "Nos adaptamos a las nuevas tecnologias para brindar un servicio seguro y confiable";
        break;
      case 7: titulod = "Recurso Humano";
        texd = "Contamos con servicio outsourcing, para brindar una atencion completamente personalizada";
        break;
      case 8: titulod = "Online Marketing";
        texd = "Contamos con herramientas para evaluar el flujo de tu sitio web";
        break;

      default:
        break;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      texto: texd,
      titulo: titulod,
      aceptar: aceptard
    };
    const dialogRef = this.dialog.open(DialogText, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        // none
      });
  }

  crearProyecto(entity: Proyectos) {
    var f: boolean = this._proyectoService.guardarProecto(entity, this._snackBar)
    //this.cargarUsuarios();
  }

  // convenience getter for easy access to form fields
  get solicitudF() { return this.solicitudForm.controls; }

  moveHeader(duration?: number) {
    this.animateScrollService.scrollToElement('header', duration)
  }

  moveBotton(duration?: number) {
    this.animateScrollService.scrollToElement('contact', duration)
  }

  moveHome(duration?: number) {
    this.animateScrollService.scrollToElement('home', duration)
  }

  moveAbout(duration?: number) {
    this.animateScrollService.scrollToElement('about', duration)
  }
};