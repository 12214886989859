

export class Usuarios {
    /*
     private idUsuario: number;
     ...
 
     constructor(id:number, ...) {
         this.idUsuario = id;
         ...
     }
     */

    //es lo mismo, pero mas corto
    constructor(
        public idUsuario: number,
        public nombre: string,
        public apellido: string,
        public correo: string,
        public password: string,
        public fechaNacimiento: Date
    ) { }
}