<!-- Main -->
<div id="main">
    <!-- Main -->
    <div class="box ">
        <footer class="major container large">
            <h3>Estas interesado en algun proyecto?</h3>
            <p>Envianos tu petición y nocotros te contactaremos.</p>
            <!-- <ul class="actions special">
                <li>
                    <button (click)="openDialogNuevo()" class="button">
                        Cotizar Proyecto
                        </button>
                </li>
            </ul> -->
            <button (click)="openDialogNuevo()" class="button">
                Cotizar Proyecto
                </button>
        </footer>
        <div class="box container">
            <section>
                <header>
                    <h3>Contactenos</h3>
                </header>
                <div class="row">
                    <div class="col-12 col-12-mobilep">
                        <div>
                            <form id="frmContacto" [formGroup]="solicitudForm" (ngSubmit)="guardarSolicitud()">
                                <div class="col-6 col-12-mobilep">
                                    <label for="">Nombre</label>
                                    <mat-form-field class="full-width">
                                        <mat-label>Nombre </mat-label>
                                        <input matInput required id="nombre" type="text" formControlName="nombre" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.nombre.errors }" />
                                        <div *ngIf="solicitudSubmitted && solicitudF.nombre.errors" class="invalid-feedback">
                                            <mat-error *ngIf="solicitudF.nombre.errors.required">nombre is required</mat-error>
                                        </div>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 col-12-mobilep ">
                                    <label for="MailUser">Correo</label>
                                    <mat-form-field class="full-width">
                                        <mat-label>Correo </mat-label>
                                        <input matInput id="MailUser" type="text" formControlName="correo" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.correo.errors }" (change)="solicitudF.correo.setValue(solicitudF.correo.value.trim())" />
                                        <div *ngIf="solicitudSubmitted && solicitudF.correo.errors" class="invalid-feedback">
                                            <mat-error *ngIf="solicitudF.correo.errors.required">Email is required</mat-error>
                                            <mat-error *ngIf="solicitudF.correo.errors.email">Email must be a valid email address</mat-error>
                                        </div>
                                    </mat-form-field>


                                </div>
                                <div class="col-6 col-12-mobilep ">
                                    <label for="telefono">Telefono</label>
                                    <mat-form-field class="full-width">
                                        <mat-label>Telefono </mat-label>
                                        <input matInput id="telefono" type="text" formControlName="telefono" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.telefono.errors }" />
                                        <div *ngIf="solicitudSubmitted && solicitudF.telefono.errors" class="invalid-feedback">
                                            <mat-error *ngIf="solicitudF.telefono.errors.required">telefono is required</mat-error>
                                        </div>
                                    </mat-form-field>

                                </div>
                                <div class="col-6 col-12-mobilep ">
                                    <label for="descripcion">Descripcion</label>
                                    <mat-form-field class="full-width">
                                        <mat-label>Descripcion </mat-label>
                                        <textarea matInput id="descripcion" type="text" formControlName="descripcion" [ngClass]="{ 'is-invalid': solicitudSubmitted && solicitudF.descripcion.errors }">
                                        </textarea>
                                        <div *ngIf="solicitudSubmitted && solicitudF.descripcion.errors" class="invalid-feedback">
                                            <mat-error *ngIf="solicitudF.descripcion.errors.required">descripcion is required</mat-error>
                                        </div>
                                    </mat-form-field>

                                </div>
                                <ul>
                                    <li class="card-container">
                                        <style>
                                            .card:hover {
                                                color: black;
                                            }
                                        </style>
                                        <button class="card btn-outline-primary">Enviar</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>