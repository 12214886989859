import { Injectable } from '@angular/core';
import { CasosUso } from '../models/caso.uso';
import { EntityParser } from '../utils/entity.parser';
import { AbSerbice } from './abstract.service';
import { RestService } from './rest.service';
import { Zurlrest } from './zurlrest';

@Injectable()
export class CasoUsoService extends AbSerbice<CasosUso> {

    private url: string = Zurlrest.caso_uso;

    constructor(public rest: RestService) {
        super(rest);
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getEntityId(data: any): number {
        var id = 0;
        try {
            id = data.idCaso;
        } catch (ignore) {
            // error parsin id of entity
        }
        if (id === 0) {
            return 0;
        } else {
            return id;
        }

    }
    protected toEntity(data: any): CasosUso {
        return EntityParser.parseCasoUso(data);
    }

}