<!-- Main -->
<div id="main">
    <!-- Main -->
    <div id="box">
        <div class="box alt">
            <section class="feature left boxe">
                <a href="#" class="image icon solid "><img src="../../assets/images/web_development.jpg" alt="" class="image" /></a>
                <div class="content">
                    <h3>Desarrollo de sitios web:</h3>
                    <p>Nos esforzamos por crear un diseño único para su proyecto que ayudará a su empresa o empresa individual a destacarse. Somos expertos en crear sitios web necesarios para satisfacer sus necesidades. Nos esforzamos por crear un diseño
                        único para su sitio web que ayuda a su empresa, corporación o empresa individual a destacarse. Si está buscando un sitio web de presencia corporativa, comercio electrónico, o incluso sólo una página de aterrizaje, estamos listos
                        para poder ayudarte.</p>


                </div>
            </section>
            <section class="feature right boxe">
                <a href="#" class="image icon solid"><img src="../../assets/images/app_development.png" alt="" class="image" /></a>
                <div class="content">
                    <h3>Desarrollo de aplicaciones móviles:</h3>
                    <p>Mejora la comunicación con tus clientes, consigue notoriedad y relanza tus ventas de una forma sencilla gracias a las apps móviles. En T.a Consulting te ayudamos a convertir en realidad cualquier aplicación para que saques todo el
                        partido a la movilidad y la disponibilidad en tiempo real.</p>
                </div>
            </section>
            <!-- 			<section class="feature left"> -->
            <!-- 				<a href="#" class="image icon solid "><img -->
            <!-- 					src="images/graphic_desing.png" alt="" class="image" /></a> -->
            <!-- 				<div class="content"> -->
            <!-- 					<h3>Diseño gráfico:</h3> -->
            <!-- 					<p>Creamos diseños que potencian la singularidad de tu negocio -->
            <!-- 						Nuestra meta en Ta consulting es crear para ti imágenes -->
            <!-- 						corporativas que reflejan el alma de tu negocio. Trasladamos tus -->
            <!-- 						ventajas competitivas a cada uno de nuestros diseños, por que -->
            <!-- 						creemos que eres único y nos esmeramos en agrandar el valor de tu -->
            <!-- 						marca.</p> -->
            <!-- 				</div> -->
            <!-- 			</section> -->
            <section class="feature left boxe">
                <a href="#" class="image icon solid "><img src="../../assets/images/software_medida.jpeg" alt="" class="image" /></a>
                <div class="content">
                    <h3>Desarrollo de software a la medida:</h3>
                    <p>Abarcamos todo el proceso que va desde la fase de consultoría previa (estudio estratégico del proyecto) al mantenimiento evolutivo del sistema pasando por el diseño y la arquitectura, el desarrollo, la fase de pruebas, control de calidad,
                        puesta en marcha y el soporte. Es decir, realizamos el ciclo completo de un desarrollo de software.</p>
                    <p>Además, para ello, nos valemos de las últimas metodologías, las que más se adaptan a cada proyecto, a cada empresa y a cada cliente. Utilizamos metodologías de gestión de proyectos clásicas y también las más ágiles y modernas.</p>
                </div>
            </section>
            <!-- 			<section class="feature left"> -->
            <!-- 				<a href="#" class="image icon solid "><img -->
            <!-- 					src="images/marketing.jpg" alt="" class="image" /></a> -->
            <!-- 				<div class="content"> -->
            <!-- 					<h3>Marketing:</h3> -->
            <!-- 					<p>Porque sabemos que el posicionamiento de marca a nivel -->
            <!-- 						digital es fundamental ofrecemos una gama de opciones como -->
            <!-- 						Posicionamiento SEO Produccion de video Marketing Creacion y -->
            <!-- 						Manejo de redes sociales Creacion de blog y contenido.</p> -->
            <!-- 				</div> -->
            <!-- 			</section> -->
            <!-- 			<section class="feature right"> -->
            <!-- 				<a href="#" class="image icon solid "><img -->
            <!-- 					src="images/outsourcing.jpg" class="image" alt="" /></a> -->
            <!-- 				<div class="content"> -->
            <!-- 					<h3>Outsourcing:</h3> -->
            <!-- 					<p>Nos caracterizamos por ser una empresa integral y -->
            <!-- 						especializada en la búsqueda, selección, reclutamiento, -->
            <!-- 						preselección de personal y recursos humanos, para todas las -->
            <!-- 						empresas e industria que operan en El Salvador, satisfaciendo así -->
            <!-- 						las necesidades de contratar o subcontratar personal. Contamos con -->
            <!-- 						personal capacitado con el objetivo de brindarle estabilidad en la -->
            <!-- 						calidad de selección de cada uno de sus empleados a contratar.</p> -->
            <!-- 				</div> -->
            <!-- 			</section> -->
            <!-- 			<section class="feature left"> -->
            <!-- 				<div class="content"> -->
            <!-- 					<p>Nos encargamos de realizar subcontratación o tercerización -->
            <!-- 						(outsourcing) de personal a todo nivel de su empresa.</p> -->
            <!-- 				</div> -->
            <!-- 			</section> -->
        </div>
    </div>
</div>