import { CasosUso } from "./caso.uso";


export class Solicitudes {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idSolicitud: number,
        public nombre: CasosUso,
        public correo: string,
        public telefono: string,
        public descripcion: number,
    ) { 
    }
}