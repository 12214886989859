import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppTypes } from '../models/app.type';
import { Proyectos } from '../models/proyectos';
import { Solicitudes } from '../models/solicitud';
import { AppTypesService } from '../services/app.types.service';
import { ProyectoService } from '../services/proyecto.service';
import { SolicitudService } from '../services/solicitud.service';

@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.css', '../app.component.css', '../../assets/css/main.css']
})
export class CotizarComponent implements OnInit {

  public solicitudForm: FormGroup;
  public solicitudSubmitted: boolean;
  public appTypes: any = [];

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private _proyectoService: ProyectoService,
    private _appTypesService: AppTypesService,
    private _solicitudService: SolicitudService,
    private _snackBar: MatSnackBar) {
    this.solicitudForm = this.formBuilder.group({});
    this.solicitudSubmitted = false;
    this.cargarAppTypes();
  }

  ngOnInit(): void {
    this.solicitudForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telefono: ['', [Validators.required]],
      descripcion: ['', [Validators.required, Validators.minLength(6)]]
      // ,fecha: [new Date, [Validators.required]]
    });

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  cargarAppTypes() {
    var pr = this._appTypesService.getList();
    console.log('apptipe cargadp: ', pr)
    if (pr) {
      this.appTypes = pr;
    }
  }

  evaluarConexion() {
    var ag = this._appTypesService.getList();
    console.log('ls: ', ag);
    //var fue = this._proyectoService.enviarCorreoTexto(16);
    //console.log('ENVIO CORREO?: ',fue);
  }

  guardarSolicitud() {
    this.solicitudSubmitted = true;
    this.evaluarConexion();
    if (this.solicitudForm.valid) {
      var solicitudq: Solicitudes = new Solicitudes(
        0,
        this.solicitudF.nombre.value,
        this.solicitudF.correo.value,
        this.solicitudF.telefono.value,
        this.solicitudF.descripcion.value
      );
      this._solicitudService.creteOne(solicitudq);
    } else {
      this.openSnackBar('completa el formulario!','ok'); 
    }
  }

  openDialogNuevo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      idProyecto: 0,
      appType: new AppTypes(1, ''),
      nombre: '',
      nombreUsuario: '',
      correo: '',
      telefono: '',
      descripcion: '',
      acuerdo: '',
      fechaEntrega: new Date(),
      presupuesto: 0.00,
      appTypesList: this.appTypes
    };
    // dialogConfig.panelClass = '';
    const dialogRef = this.dialog.open(DialogCotizar, dialogConfig);
    dialogRef.updateSize(window.innerWidth.toString(), '100%');
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          console.log("Dialog output:", result);
          this.crearProyecto(result);
        } else {
          console.log('Dialog vacio');
        }
      });
  }

  crearProyecto(entity: Proyectos) {
    var f: boolean = this._proyectoService.guardarProecto(entity,this._snackBar)
    if (f) {
      this.openSnackBar('Proyecto Resgistrado','ok'); 
    }
    //this.cargarUsuarios();
  }
  // convenience getter for easy access to form fields
  get solicitudF() { return this.solicitudForm.controls; }
}

@Component({
  selector: 'dialog-form-cotizar',
  templateUrl: './dialogs/proyecto.dialog.html'
  , styleUrls: ['./cotizar.component.css', '../app.component.css', '../../assets/css/main.css']
})
export class DialogCotizar implements OnInit {

  public proyectoForm: FormGroup;
  public proyectoSubmitted: boolean;
  public appTypes: any = [];
  public proyecto: Proyectos;
  public submitted = false;
  public columsSpawn = 2;
  public innerWidths = '0';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogCotizar>,
    @Inject(MAT_DIALOG_DATA) public data: {
      idProyecto: number, appType: AppTypes, nombre: string, nombreUsuario: string, correo: string
      , telefono: string, descripcion: string, acuerdo: string, fechaEntrega: Date
      , presupuesto: number, appTypesList: AppTypes[]
    }
    , private _snackBar: MatSnackBar
  ) {
    this.proyecto = new Proyectos(data.idProyecto, data.appType, data.nombre, data.nombreUsuario
      , data.correo, data.telefono, data.descripcion,
      data.acuerdo, data.fechaEntrega, data.presupuesto);
    this.proyectoForm = this.fb.group({});
    this.proyectoSubmitted = false;
    this.appTypes = data.appTypesList;
    this.innerWidths = (window.innerWidth*0.8)+ 'px';
    if (window.innerWidth > 600) {
      this.columsSpawn = 1;
    }else{
      this.columsSpawn = 2;
    }
  }

  ngOnInit(): void {
    var pipe = new DatePipe('en-US');
    this.proyectoForm = this.fb.group({
      idProyecto: [this.proyecto.idProyecto, [Validators.required]],
      nombre: [this.proyecto.nombre, [Validators.required]],
      nombreUsuario: [this.proyecto.nombreUsuario, [Validators.required]],
      correo: [this.proyecto.correo, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telefono: [this.proyecto.telefono, [Validators.required, Validators.minLength(8)]],
      descripcion: [this.proyecto.descripcion, [Validators.required]],
      acuerdo: [this.proyecto.acuerdo, [Validators.required]],
      fechaEntrega: [pipe.transform(this.proyecto.fechaEntrega, 'yyyy-MM-dd'), [Validators.required]],
      presupuesto: [this.proyecto.presupuesto, [Validators.required]],
      appType: [this.proyecto.appType, [Validators.required]]
    });
    if (window.innerWidth > 800) {
      this.columsSpawn = 1;
    }else{
      this.columsSpawn = 2;
    }
    console.log('innerWidth',window.innerWidth);
    console.log('columsSpawn',this.columsSpawn);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.proyectoForm.invalid) {
      this.openSnackBar('Complete el formulario!','ok'); 
      return;
    }
    this.proyecto.idProyecto = this.proyectoF.idProyecto.value;
    this.proyecto.appType = this.proyectoF.appType.value;
    this.proyecto.nombre = this.proyectoF.nombre.value;
    this.proyecto.nombreUsuario = this.proyectoF.nombreUsuario.value;
    this.proyecto.correo = this.proyectoF.correo.value;
    this.proyecto.telefono = this.proyectoF.telefono.value;
    this.proyecto.descripcion = this.proyectoF.descripcion.value;
    this.proyecto.acuerdo = this.proyectoF.acuerdo.value;
    this.proyecto.fechaEntrega = this.proyectoF.fechaEntrega.value;
    this.proyecto.presupuesto = this.proyectoF.presupuesto.value;
    this.dialogRef.close(this.proyecto);
  }

  get proyectoF() { return this.proyectoForm.controls; }
}
