import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AboutComponent } from './about/about.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CotizarComponent, DialogCotizar } from './cotizar/cotizar.component';
import { AdicionalesService } from './services/adicionales.service';
import { AppModuloSeleccionadoService } from './services/app.modulos.seleccionado.service';
import { AppModuloService } from './services/app.modulos.service';
import { AppTypesService } from './services/app.types.service';
import { CasoUsoSeleccionadoService } from './services/caso.uso.seleccionado.service';
import { CasoUsoService } from './services/caso.uso.service';
import { ImagenService } from './services/imagen.service';
import { ProyectoService } from './services/proyecto.service';
import { RestService } from './services/rest.service';
import { SolicitudService } from './services/solicitud.service';
import { AppComponentTc } from './tc/app.component.tc';
import { WhatComponent } from './what/what.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DialogText } from './tc/dialogs/text.dialog';

const materialModules = [
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatTableModule,
  MatGridListModule,
  MatSnackBarModule,
  MatCarouselModule
]

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    WhatComponent,
    CotizarComponent,
    DialogCotizar,
    AppComponentTc,
    DialogText
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    AmplifyAngularModule,
    AmplifyUIAngularModule,
    NgbCarouselModule,
    materialModules
  ],
  exports: [
    materialModules
  ],
  providers: [
    AmplifyService, AdicionalesService,
    AppModuloSeleccionadoService,
    AppModuloService, AppTypesService,
    CasoUsoSeleccionadoService, CasoUsoService,
    ImagenService, ProyectoService,
    SolicitudService,
    RestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
