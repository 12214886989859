import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what',
  templateUrl: './what.component.html',
  styleUrls: ['./what.component.css','../app.component.css','../../assets/css/main.css']
})
export class WhatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
