import { AppModulos } from "./app.modulo";


export class CasosUso {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idCaso: number,
        public appModulo: AppModulos,  
        public nombre: string,
        public precioInicial: number
    ) { }
}