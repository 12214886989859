import { AppTypes } from "./app.type";


export class AppModulos {
    /*
    private idAdicional: number;
    ...

    constructor(id:number, ...) {
        this.idAdicional = id;
        ...
    }
    */

    //es lo mismo, pero mas corto
    constructor(
        public idModulo: number,
        public appType: AppTypes,
        public nombre: string
    ) { }
}